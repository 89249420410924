<template>
  <div>
    
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/HYXW' }">会议新闻</el-breadcrumb-item>
      <el-breadcrumb-item>重要会议</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="list">
      <div class="card" v-for="(item, i) in list" :key="'card' + i" @click="onClick(item)">
        <div class="courseWrap">
          <div class="courseImgWrap">
            <img :src="item.img" class="image">
          </div>
          <div class="courseConWrap">
            <h2 class="courseName mb20">{{ item.label }}</h2>
            <div class="courseInfo">
              <span>会议时间：{{ item.time }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
export default {
  name: "HY_list",
  data() {
    return {
      list: [
        {
          label: "共谋急诊影像质控新发展",
          link: '/HY20240828',
          img: require('@/assets/HY20240828/1.jpg'),
          time: "2024-08-28",
        },
        {
          label: "国家级放射影像专业质控中心X线技术亚专业组第二次会议成功举行",
          link: '/HY20240827',
          img: require('@/assets/HY20240827/1.png'),
          time: "2024-08-27",
        },
        {
          label: "国家级放射影像专业质控中心工作会议",
          link: '/HY20240815',
          img: require('@/assets/HY20240815/1.png'),
          time: "2024-08-09",
        },
        {
          label: "青岛市放射影像质控中心2024年危急值专题讨论会",
          link: '/HY20240812',
          img: require('@/assets/HY20240812/1.png'),
          time: "2024-08-06",
        },
        {
          label: "自治区放射专业质控中心工作会议及放射质量控制能力提高学习班在新医大一附院举办（转载）",
          link: '/HY20240724',
          img: require('@/assets/HY20240724/1.png'),
          time: "2024-07-17",
        },
        {
          label: "河南省放射影像质控中心2024年第一次专题培训会议圆满召开（转载）",
          link: '/HY20240723',
          img: require('@/assets/HY20240723/1.png'),
          time: "2024-06-22",
        },
        {
          label: "【协和帮扶】贵州省放射影像专业质控中心2024年上半年工作会召开（转载）",
          link: '/HY20240712',
          img: require('@/assets/HY20240712/1.png'),
          time: "2024-07-09",
        },
        {
          label: "共筑医疗质控健康防线，推动放射影像高质发展",
          link: '/HY20240701',
          img: require('@/assets/HY20240701/1.png'),
          time: "2024-06-25",
        },
        {
          label: "2024年度河北省医学影像质控中心工作会议成功举办",
          link: '/HY20240623',
          img: require('@/assets/HY20240623/1.png'),
          time: "2024-05-25",
        },
        {
          label: "2024年国家级放射影像专业质控中心亚专业组组长第一次会议成功召开",
          link: '/HY20240622',
          img: require('@/assets/HY20240622/1.png'),
          time: "2024-05-11",
        },
        {
          label: "严把医疗质量关，筑牢生命安全线—2024年广东省放射影像专业质控中心工作会议简报",
          link: '/GDS2024',
          img: require('@/assets/GDS2024/1.png'),
          time: "2024-04-20",
        },
        {
          label: "2024年国家级放射影像专业质控中心深圳工作会议",
          link: '/SZ2024',
          img: require('@/assets/SZ2024/1.png'),
          time: "2024-04-18",
        },
        {
          label: "吉林省医学影像科质控中心成功举办2024年度吉林省医学影像科质控中心会议",
          link: '/JLS2024',
          img: require('@/assets/JLS2024/1.png'),
          time: "2024-04-12",
        },
        {
          label: "国家放射影像专业质控中心专题讲座及2024年国家级放射影像专业质控中心专家委员会第一次工作会议成功举办",
          link: '/GJJ2024',
          img: require('@/assets/GJJ2024/1.jpg'),
          time: "2024-03-29",
        },
        {
          label: "黑龙江省放射影像专业质控中心专家委员会工作交流会胜利召开",
          link: '/HLJZJH',
          img: require('@/assets/hljzjh/1.png'),
          time: "2024-03-30",
        },
        {
          label: "贵州省放射影像专业质控中心“基于病例的质控培训”第二期成功举办",
          link: '/GZSPX',
          img: require('@/assets/gzpx/1.png'),
          time: "2024-03-26",
        },
        {
          label: "2024年山东省医学影像质控中心工作会议简报",
          link: '/SDSHYJB',
          img: require('@/assets/sdsyx/1.png'),
          time: "2024-03-08",
        },
        {
          label: '“影像”联盟 和衷共济（转载）',
          link: '/XSJX',
          img: require('@/assets/xw/img1.jpg'),
          time: "2024-01-09",
        },
      ]
    }
  },
  methods: {
    onClick(item) {
      this.$router.push(item.link)
    }
  }
}
</script>

<style scoped>
.list {
  margin: 20px;
}

.card {
  border: 1px #e3e4e6 solid;
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 20px;
  background-color: #fff;
}

.courseWrap {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer;
  margin-right: 20px;
}

.courseImgWrap {
  min-height: 130px;
  min-width: 224px;
}

.courseConWrap {
  color: #888;
  margin-left: 20px;
}

.courseName {
  line-height: 28px;
  font-weight: bold;
  font-size: 18px;
  color: #333;
  /* text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; */
}

.mb20 {
  margin-bottom: 20px;
}

.courseInfo {
  line-height: 20px;
  font-size: 14px;
  color: #afafaf;
}

.image {
  width: 224px;
  display: block;
}
</style>